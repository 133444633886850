<form class="new-message" (submit)="sendMessage()">
    <div class="avatar-container">
        <a  href="javascript:;" 
            class="avatar" 
            (click)="showEditAvatar()" 
            [style.background-image]="'url(' + user?.avatarUrl + ')'"
            ></a>
    </div>
    <div class="text-container">
        <div class="field-container">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{label}}</mat-label>
                <textarea 
                    #textarea
                    name="message" 
                    placeholder="Type your comment"
                    matInput
                    cdkTextareaAutosize 
                    (keydown)="onKeyDown($event)"
                    (blur)="onBlur()"
                    [disabled]="sending"
                    [(ngModel)]="text"></textarea>
            </mat-form-field>
            <div #autocompleteContainer class="autocomplete-container">
                <div #autocomplete class="autocomplete" [class.visible]="autocompleteVisible">

                    <div>
                        <strong>{{completionPrefix}}</strong>...
                    </div>

                    <a 
                        mat-button 
                        *ngFor="let option of autocompleteOptions; index as index" 
                        (click)="activateAutoComplete(option)"
                        [class.active]="autoCompleteSelected === index" 
                        >
                        {{option.label}}
                    </a>
                </div>
            </div>
        </div>

        <mat-spinner *ngIf="sending" class="icon loading" diameter="18" strokeWidth="2"></mat-spinner>
        <div *ngIf="sendError" class="error-message" [class.expanded]="expandError">
            <mat-icon *ngIf="sendError">error</mat-icon>
            {{sendError.message}}
        </div>
        <emoji-selector-button 
            class="top-right"
            (selected)="insertEmoji($event)"
            ></emoji-selector-button>
    </div>
    <div class="actions">
        <ng-container *ngIf="!user">
            <button 
                mat-raised-button 
                color="primary"
                type="button"
                (click)="showSignIn()"
                >{{signInLabel}}</button>
        </ng-container>
        <ng-container *ngIf="user">
            <button 
                *ngIf="canComment"
                mat-raised-button 
                class="send"
                color="primary"
                [disabled]="!text || sending" 
                >
                <mat-icon *ngIf="!sending">chevron_right</mat-icon>
                <mat-spinner *ngIf="sending" class="icon" diameter="18" strokeWidth="2"></mat-spinner>
                <ng-container *ngIf="!sending">
                    {{sendLabel}}
                </ng-container>
                <ng-container *ngIf="sending">
                    {{sendingLabel}}
                </ng-container>

                </button>
            <button 
                *ngIf="!canComment"
                type="button"
                (click)="showPermissionDenied()"
                mat-raised-button 
                color="primary"
                >{{permissionDeniedLabel}}</button>
        </ng-container>
    </div>
</form>
