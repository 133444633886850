<banta-chat-view 
    #chatView
    [source]="source"
    (upvoted)="upvote($event)"
    (reported)="report($event)"
    (selected)="select($event)"
    (userSelected)="selectUser($event)"
    ></banta-chat-view>

<form class="new-message" (submit)="sendMessage()">
    
    <div class="entry-container">
        <input
            type="text"
            name="message" 
            (keydown)="onKeyDown($event)"
            [(ngModel)]="newMessage.message" />
            
        <emoji-selector-button
            (selected)="insertEmoji($event)"
            ></emoji-selector-button>
    </div>

    <div class="actions">

        <ng-container *ngIf="!user">
            <button type="button" (click)="showSignIn()" mat-raised-button color="primary">{{signInLabel}}</button>
        </ng-container>
        <ng-container *ngIf="user">
            <button *ngIf="canChat" [disabled]="!newMessage.message" mat-raised-button color="primary">{{sendLabel}}</button>
            <button *ngIf="!canChat" type="button" (click)="sendPermissionError()" mat-raised-button color="primary">{{permissionDeniedLabel}}</button>
        </ng-container>
    </div>
</form>