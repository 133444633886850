<!--The content below is only a placeholder and can be replaced.-->
<nav>
  <a routerLink="/">
    <img class="logo" src="/assets/logo.svg" />
  </a>
  <a mat-button routerLink="/source" routerLinkActive="active">Source</a>
  <a mat-button routerLink="/try" routerLinkActive="active">Try it out</a>
  <a mat-button routerLink="/dev" routerLinkActive="active">Development</a>
  <div class="spacer"></div>
</nav>
<main>
  <router-outlet></router-outlet>
</main>

<footer>
  <div>
    <a href="javascript:;" (click)="showAbout()">About</a>
    | <a href="javascript:;" (click)="darkTheme = !darkTheme">
      <ng-container *ngIf="darkTheme">
        Light Mode
      </ng-container>
      <ng-container *ngIf="!darkTheme">
        Dark Mode
      </ng-container>
    </a>
  </div>
  <a href="https://astronautlabs.com/" target="_blank">
    Astronaut Labs, LLC
  </a>
  <div class="copyright">
    &copy; 2019-{{year}}
  </div>
</footer>