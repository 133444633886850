
<mat-menu #pointItemMenu="matMenu">
    <button mat-menu-item (click)="report()">Report</button>
    <button mat-menu-item>Help</button>
</mat-menu>

<div class="message-content">
    <div class="user">
        <a 
            href="javascript:;"
            class="avatar"
            (click)="selectUser()"
            [style.background-image]="avatarForUser(message.user)"></a>
        <a href="javascript:;" class="display-name" (click)="selectUser()">{{message.user.displayName}}</a>
        <a href="javascript:;" class="username" (click)="selectUser()">@{{message.user.username}}</a>

        <button mat-icon-button [matMenuTriggerFor]="pointItemMenu">
            <mat-icon [inline]="true">more_vert</mat-icon>
        </button>
    </div>
    <div class="content">
        {{message.message}}
    </div>
    
    <div class="actions">
        <banta-timestamp [value]="message.sentAt"></banta-timestamp>
        <div class="spacer"></div>
        <div class="counted-action" *ngIf="showReplyAction">
            <div class="count-indicator">
                {{message.submessages?.length || 0}}
            </div>
            <button mat-icon-button matTooltip="Comment" matTooltipPosition="below" (click)="select()">
                <mat-icon [inline]="true">comment</mat-icon>
            </button>
        </div>
        <div class="counted-action">
            <div class="count-indicator">  
                {{message.upvotes}}
            </div>
            <button mat-icon-button matTooltip="Upvote" matTooltipPosition="below" (click)="upvote()">
                <mat-icon [inline]="true">thumb_up</mat-icon>
            </button>
        </div>
    </div>
</div>
