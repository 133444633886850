
<div class="focused" [class.visible]="selectedMessageVisible" *ngIf="selectedMessage">

    <div>
        <a mat-button href="javascript:;" (click)="unselectMessage()">
            <mat-icon>arrow_back</mat-icon>
            Latest Comments
        </a>
    </div>

    <banta-comment 
        [message]="selectedMessage"
        ></banta-comment>

    <div class="replies">

        <ng-container *ngIf="!selectedMessageThread">
            <div class="loading">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedMessageThread">
            <banta-comment-view 
                [source]="selectedMessageThread"
                [allowReplies]="false"
                [fixedHeight]="false"
                [showEmptyState]="false"
                [newestLast]="true"
                ></banta-comment-view>

            <banta-comment-field
                [sendLabel]="replyLabel"
                [sendingLabel]="sendingLabel"
                [hashtags]="hashtags"
                [participants]="participants"
                (signInSelected)="showSignIn()"
                (editAvatarSelected)="showEditAvatar()"
                [source]="selectedMessageThread"
                [canComment]="canComment"
                [signInLabel]="signInLabel"
                [user]="user"
                [label]="postReplyLabel"
                ></banta-comment-field>
        </ng-container>
    </div>
</div>

<div class="main" [class.hidden]="selectedMessage">
    <banta-comment-field
        [source]="source"
        [user]="user"
        [sendLabel]="sendLabel"
        [sendingLabel]="sendingLabel"
        [signInLabel]="signInLabel"
        [canComment]="canComment"
        [hashtags]="hashtags"
        [participants]="participants"
        [label]="postCommentLabel"
        (editAvatarSelected)="showEditAvatar()"
        (signInSelected)="showSignIn()"
        ></banta-comment-field>

    <banta-comment-view 
        [class.faded]="selectedMessage"
        [source]="source"
        [fixedHeight]="fixedHeight"
        [maxMessages]="maxMessages"
        [maxVisibleMessages]="maxVisibleMessages"
        [genericAvatarUrl]="genericAvatarUrl"
        (userSelected)="selectMessageUser($event)"
        (selected)="selectMessage($event)"
        (upvoted)="upvoteMessage($event)"
        (reported)="reportMessage($event)"
        ></banta-comment-view>
</div>