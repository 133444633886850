<div class="message-container" #messageContainer>
    <ng-content select="[data-before]"></ng-content>
    <banta-chat-message 
        *ngFor="let message of messages"
        [message]="message"
        [class.highlight]="mentionsMe(message)" 
        [class.flash]="message.id === flashedMessageId"
        (selected)="selectMessage(message)"
        (upvoted)="upvoteMessage(message)"
        (reported)="reportMessage(message)"
        (userSelected)="selectMessageUser(message)"
        [attr.data-id]="message.id"
        >
        </banta-chat-message>
    <ng-content select=":not([data-before])"></ng-content>
</div>