<div class="message-container">
    <ng-content select="[data-before]"></ng-content>
    
    <a mat-button class="nav" [class.visible]="isViewingMore" href="javascript:;" (click)="showNew()">
        Show 
        <ng-container *ngIf="newMessages.length === 1">
            {{newMessages.length}} new message
        </ng-container>
        <ng-container *ngIf="newMessages.length > 1">
            {{newMessages.length}} new messages
        </ng-container>
        <ng-container *ngIf="newMessages.length === 0">
            new messages
        </ng-container>
    </a>

    <ng-container *ngIf="messages.length === 0">
        <div class="empty-state" *ngIf="showEmptyState">
            Be the first to comment!
        </div>
    </ng-container>
    <banta-comment 
        *ngFor="let message of messages; trackBy: messageIdentity"
        class="abbreviated"
        [message]="message"
        (click)="isViewingMore = true"
        [showReplyAction]="allowReplies"
        (userSelected)="selectMessageUser(message)"
        (upvoted)="upvoteMessage(message)"
        (reported)="reportMessage(message)"
        (selected)="selectMessage(message)"
        ></banta-comment>

    <a mat-button class="nav" [class.visible]="hasMore && !isLoadingMore" href="javascript:;" (click)="showMore()">Show more</a>

    <div class="loading-more" *ngIf="isLoadingMore">
        <mat-spinner></mat-spinner>
    </div>

    <!-- <div style="color: #666">
        n={{newMessages.length}}, m={{messages.length}}, o={{olderMessages.length}},
        v={{maxVisibleMessages}}, M={{maxMessages}}
    </div> -->

    <ng-content select=":not([data-before])"></ng-content>
</div>